import React, { useEffect, useState } from 'react';
import { useTypingEffect } from '~/utils/use-typing-effect';

type TextTypingEffectProps = {
  items: string[];
  isTypeByLetter?: boolean;
  duration?: number;
};

export const BannerText: React.FC<TextTypingEffectProps> = ({
  items,
  isTypeByLetter = true,
  duration = 200,
}) => {
  const [itemIndex, setItemIndex] = useState(0);

  const textToShow = useTypingEffect(items[itemIndex], duration, isTypeByLetter);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setItemIndex((prevIndex) => (prevIndex >= items.length - 1 ? 0 : prevIndex + 1));
    }, 5000);

    return () => {
      clearInterval(intervalId);
    };
  }, [items.length]);

  return (
    <span key={itemIndex}>
      {textToShow}
      <span className="animate-pulse duration-long">|</span>
    </span>
  );
};
